import React, { useEffect, useState } from "react";
import Button from "../../../components/Buttons/Button/Button";
import InputField from "../../../components/InputField";
import TextAreaField from "../../../components/TextAreaField";
import editTitleIcon from "../../../assets/icons/admin/edit-title-icon.svg";
import closeIcon from "../../../assets/icons/closeIcon.png";
import "./EditReservation.scss";
import moment from "moment";
import { PATH_PROPERTY } from "../../../Util/constants";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "react-modal";
import AuthService from '../../../services/auth.service.js';
import swal from 'sweetalert';

const EditReservation = (props) => {
  const { reservationJSON, onClose, agent, agency } = props;
  const [res, setRes] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const property = location?.state?.property;
  const fullCalendar = location?.state?.fullCalendar;
  const activeRatePlan = location?.state?.activeRatePlan;
  const agentProp = JSON.parse(localStorage.getItem("agent"));
  useEffect(() => {
    const load = async () => {
      try {
        console.log("CONFIRM details:", JSON.parse(reservationJSON));
        setRes(JSON.parse(reservationJSON));
      } catch (error) {
        console.error("Failed to parse reservation JSON:", error);
        setRes(null);
      }
    };
    load();
  }, [reservationJSON]);
  

  const handleResConfirmation = async () => {
    try {
      const guestyResponse = await fetch('https://api.triangle.luxury/reservations', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbklkIjoiNjUwMDViMDk1NDlmMDNlNzk4NjZkMDgyIiwiYXBwbGljYXRpb25JZCI6IjY0MTJmNGI4NDA5OTIxZjVmM2VhY2M3YiIsImFjY291bnRJZCI6IjU4NWEzOWRiZTQzOTAwMTAwMDE3ZTllOCIsImlzcyI6InByb2R1Y3Rpb24uZ3Vlc3R5LmNvbSIsIm5hbWUiOiJhcHBsaWNhdGlvbiIsInJvbGUiOiJ1c2VyIiwidXNlclJvbGVzIjpbeyJyb2xlSWQiOnsicGVybWlzc2lvbnMiOlsibGlzdGluZy52aWV3ZXIiXX19XSwicmVxdWVzdGVyIjoiT1RBQGd1ZXN0eS5jb20iLCJpYXQiOjE2OTQ1MjIxMjF9.T9mipbElgvz1nGm4YeeMDaWuzbgpg5ctUsIO3_LCrgY'
        },
        body: JSON.stringify(res.resPayloadGS),
      });
      console.log('API response from SHUB',guestyResponse)
      if (!guestyResponse.ok) {
        throw new Error("Failed to update reservation in Guesty");
      } else {
        swal("Success", "Reservation is approved at channel:GUESTY", "success");
      }

      const response = await AuthService.updateReservationStatus(res.reservationID, 'approved');
      console.log(response);
      swal("Success", "Reservation is approved", "success");
    } catch (error) {
      console.error("Error confirming reservation:", error);
      swal("Error", "Failed to approve reservation", "error");
    }
    onClose();
  };

  const handleResCancelation = async () => {
    try {
      const response = await AuthService.updateReservationStatus(res.reservationID, `canceled_by_agent`);
      console.log(response);
      swal("Success", "Reservation is successfuli canceled", "success");
    } catch (error) {
      console.error("Error confirming reservation:", error);
      swal("Error", "Failed to approve reservation", "error");
    }
    onClose();
  };

  // const handleDecline = async () => {
  //   try {
  //     const response = await AuthService.updateReservationStatus(res.reservationID, 'declined');
  //     console.log(response);
  //     swal("Success", "Reservation is declined", "success");
  //   } catch (error) {
  //     console.error("Error declining reservation:", error);
  //     swal("Error", "Failed to decline reservation", "error");
  //   }
  //   onClose();
  // };

  //   const handleResConfirmation = async () => {
  //   try {
  //     const response = await AuthService.updateReservationStatus(res.reservationID, 'approved');
  //     console.log(response);
  //     swal("Success", "Reservation is approved", "success");
  //   } catch (error) {
  //     console.error("Error confirming reservation:", error);
  //     swal("Error", "Failed to approve reservation", "error");
  //   }
  //   onClose();
  // };


  const handleDecline = async () => {
    try {
      const newPayload = { ...res.resPayloadGS };
      newPayload.ResStatus = "Cancel";

      const accommodationFare = res.resPayloadGS.HotelReservations.HotelReservation.RoomStays.RoomStay[0].tpa_Extensions.FinCollection.find(f => f.NormalType === "AF")?.Amount || 0;
      const startDate = new Date(res.resPayloadGS.HotelReservations.HotelReservation.RoomStays.RoomStay[0].TimeSpan.Start);
      const today = new Date();
      let cancellationFeeAmount;

      const diffDays = Math.ceil((startDate - today) / (1000 * 60 * 60 * 24));
      if (diffDays >= 60) {
        cancellationFeeAmount = 0;
      } else {
        cancellationFeeAmount = accommodationFare * 0.5;
      }

      newPayload.HotelReservations.HotelReservation.RoomStays.RoomStay[0].tpa_Extensions.FinCollection.forEach(fin => {
        if (fin.NormalType !== "CFE") {
          fin.Amount = 0;
        }
      });

      const existingCancellationFee = newPayload.HotelReservations.HotelReservation.RoomStays.RoomStay[0].tpa_Extensions.FinCollection.find(fin => fin.NormalType === "CFE");
      if (existingCancellationFee) {
        existingCancellationFee.Amount = cancellationFeeAmount;
      } else {
        newPayload.HotelReservations.HotelReservation.RoomStays.RoomStay[0].tpa_Extensions.FinCollection.push({
          "NormalType": "CFE",
          "Amount": cancellationFeeAmount,
          "Description": "Cancellation fee",
          "CreatedBy": "VillaTracker"
        });
      }

      newPayload.HotelReservations.HotelReservation.ResGlobalInfo.Total.AmountBeforeTax = 0;
      newPayload.HotelReservations.HotelReservation.ResGlobalInfo.Total.AmountAfterTax = 0;
      newPayload.HotelReservations.HotelReservation.ResGlobalInfo.depositPayments.AmountPercent.Amount = 0;

      console.log(JSON.stringify(newPayload));

      const guestyResponse = await fetch('https://api.triangle.luxury/reservations', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbklkIjoiNjUwMDViMDk1NDlmMDNlNzk4NjZkMDgyIiwiYXBwbGljYXRpb25JZCI6IjY0MTJmNGI4NDA5OTIxZjVmM2VhY2M3YiIsImFjY291bnRJZCI6IjU4NWEzOWRiZTQzOTAwMTAwMDE3ZTllOCIsImlzcyI6InByb2R1Y3R5LmNvbSIsIm5hbWUiOiJhcHBsaWNhdGlvbiIsInJvbGUiOiJ1c2VyIiwidXNlclJvbGVzIjpbeyJyb2xlSWQiOnsicGVybWlzc2lvbnMiOlsibGlzdGluZy52aWV3ZXIiXX19XSwicmVxdWVzdGVyIjoiT1RBQGd1ZXN0eS5jb20iLCJpYXQiOjE2OTQ1MjIxMjF9.T9mipbElgvz1nGm4YeeMDaWuzbgpg5ctUsIO3_LCrgY'
        },
        body: JSON.stringify(newPayload),
      });
      if (!guestyResponse.ok) {
        throw new Error("Failed to update reservation in Guesty");
      }

      const response = await AuthService.updateReservationStatus(res.reservationID, 'declined');
      console.log(response);
      swal("Success", "Reservation is declined", "success");
    } catch (error) {
      console.error("Error updating reservation status:", error);
      swal("Error", "Failed to decline reservation", "error");
    }
    onClose();
  };

  const goToProp = (id) => {
    console.log("going to ", id);
    history.push(PATH_PROPERTY + '/' + id);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    if (isNaN(d)) {
      return new Date().toISOString().split("T")[0];
    }
    return d.toISOString().split("T")[0];
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const renderGSPriceAndRes = () => {
    if (!res?.resPayloadGS) return null;

    const { POS, HotelReservations, EchoToken, ResStatus, TimeStamp } = res.resPayloadGS;
    console.log(JSON.stringify(res.resPayloadGS));
    return (
      <div>
        <h3>POS</h3>
        <pre>{JSON.stringify(POS, null, 2)}</pre>
        <h3>Hotel Reservations</h3>
        <pre>{JSON.stringify(HotelReservations, null, 2)}</pre>
        <h3>Echo Token</h3>
        <pre>{EchoToken}</pre>
        <h3>Reservation Status</h3>
        <pre>{ResStatus}</pre>
        <h3>Timestamp</h3>
        <pre>{TimeStamp}</pre>
      </div>
    );

  };
console.log('agentProp?.role', agentProp);
  return (
    <>
      <img src={closeIcon} className="popup-close-icon" onClick={onClose} />
      <div className="popup-wrapper">
        <div className="modal" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered modal-lg ">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="text-center page-title" style={{ marginLeft: 'auto', marginBottom: '-10px' }}>
                  <img src={editTitleIcon} alt="image" />
                  {" Reservation #" + res?.reservationID}</h3>
                <button type="button" style={{ marginTop: '-32px', padding: 'unset' }} className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
              </div>
              <hr></hr>
              <h2 className="text-center property-title text-decoration-underline cst-cursor" style={{ marginBottom: '-10px' }} onClick={() => goToProp(res?.propertyId)}>{res?.propertyName}</h2>
              <h3 className="text-center property-title-bottom" style={{ fontSize: '20px', marginTop: '5px' }}>reserved by:{res?.agentName} ({res?.agencyName})</h3>
              <div className="modal-body" style={{ marginTop: '-20px' }}>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Client Full Name"
                      value={res?.guestFirstName + ' ' + res?.guestLastName}
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Email"
                      value={res?.guestEmail}
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Phone"
                      value={res?.guestPhoneNumbers}
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Guest client ID"
                      value={res?.client_id}
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="FLYWIRE Payment Status"
                      value={res?.flywire?.data?.status}
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Status"
                      value={res?.status}
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Destination"
                      value={res?.destination}
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Nightly rate"
                      value={(res?.nightlyBasePrice * 2).toFixed(2)}
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Arrive"
                      value={moment(res?.startDate).format("MM.DD.YYYY")}
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    />{" "}
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Depart"
                      value={moment(res?.endDate).format("MM.DD.YYYY")}
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    />{" "}
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Nights"
                      value={res?.nights}
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Guests"
                      value={res?.numberOfGuests}
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Total"
                      value={parseInt(res?.total) + res?.currency}
                      disabled={true}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                  <div className="col-md-6 col-12 mb-5">
                    <TextAreaField
                      label="Notes"
                      value={res?.notes}
                      placeholder={"Enter notes"}
                      style={{ height: "60px", marginTop: "20px" }}
                    >
                    </TextAreaField>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <hr></hr>
                {agentProp?.role !== 'admin' && res?.status === 'pending' && (
                     <Button
                     style={{
                       fontSize: '18px',
                       backgroundColor: 'red',
                       marginRight: '50px',
                       width: '200px',
                     }}
                     text="Cancel reservation"
                     onClick={handleResCancelation}
                   />
                )}
            
                {agentProp?.role === 'admin' ? (
                  <>
                    <div className="d-flex justify-content-center mt-1">
                      <button onKeyDown={''} onClick={openModal}>RRR</button>
                    </div>
                    {res?.status === 'pending' && (
                      <Button
                        style={{
                          fontSize: '18px',
                          backgroundColor: '#1B9C5D',
                          marginRight: '50px',
                          width: '100px',
                        }}
                        text="Approve"
                        onClick={handleResConfirmation}
                      />
                    )}

                    <Button
                      style={{
                        fontSize: '18px',
                        backgroundColor: '#FF0000',
                        marginRight: '50px',
                        width: '100px',
                      }}
                      text="Decline"
                      onClick={handleDecline}
                    />
                  </>
                ) : (
                  <p>Reservation is {res?.status} to be approved by VillaTracker team</p>
                )}
                <Button

                  style={{ fontSize: "18px", marginRight: "30px", marginRight: '50px', width: '100px' }}
                  variant="link"
                  text="Close"
                  onClick={onClose}
                />

              </div>
            </div>
          </div>
        </div>
        {modalIsOpen && (
          <div className="custom-popup open">
            <div className="custom-popup-content">
              <h2>GS Price and Reservation</h2>
              {renderGSPriceAndRes()}
              <button onClick={closeModal}>Close</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditReservation;
