import React from 'react'

const Logo = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 1000 1000" >
            <defs>
                <style dangerouslySetInnerHTML={{ __html: ".cls-1{fill-rule:evenodd;}.cls-1,.cls-2{fill:#44c8f5;}.cls-3{fill:#284866;}" }} />
            </defs>
            <g>
                <g>
                    <g>

                        <path className="cls-2" d="M144.16,824.29l-43.59-126h28.94l26.74,94.14,24.54-84.61c1.47-6.35,5.61-9.52,12.46-9.52h18.68l-42.86,126h-24.91Z" />
                        <path className="cls-2" d="M215.28,698.29h13.18c3.42,0,6.35,1.23,8.79,3.66,2.44,2.45,3.66,5.38,3.66,8.79v7.69h-25.64v-20.15Zm0,28.57h13.18c3.42,0,6.35,1.22,8.79,3.66,2.44,2.45,3.66,5.37,3.66,8.79v84.98h-25.64v-97.43Z" />
                        <path className="cls-2" d="M246.72,698.29h13.19c3.42,0,6.35,1.16,8.79,3.48,2.44,2.32,3.66,5.19,3.66,8.61v113.92h-25.64v-126Z" />
                        <path className="cls-2" d="M279.69,698.29h13.19c3.42,0,6.35,1.16,8.79,3.48,2.44,2.32,3.66,5.19,3.66,8.61v113.92h-25.64v-126Z" />
                        <path className="cls-2" d="M373.27,820.26c-5.13,2.69-10.56,4.33-16.3,4.94-5.74,.61-10.8,.92-15.2,.92-8.79,0-16.48-2.38-23.08-7.14-6.59-4.76-9.89-12.64-9.89-23.63,0-10.26,2.99-17.58,8.97-21.98,5.98-4.4,13.61-7.33,22.89-8.79,1.22,0,2.69-.18,4.4-.55,1.71-.37,3.66-.67,5.86-.92,10.74-1.47,16.12-4.63,16.12-9.52,0-3.66-1.89-6.05-5.68-7.14-3.79-1.1-7.27-1.65-10.44-1.65-3.42,0-6.47,.49-9.16,1.47-2.69,.98-4.64,2.81-5.86,5.49h-24.54c.97-7.56,4.4-13.79,10.26-18.68,6.59-5.61,15.99-8.42,28.2-8.42,13.67,0,23.93,2.32,30.77,6.96,7.08,4.89,10.62,11.72,10.62,20.51v33.33c0,9.77-1.77,17.4-5.31,22.89-3.54,5.49-7.75,9.46-12.64,11.9Zm-5.86-45.05c-3.42,1.71-8.19,3.3-14.29,4.76l-8.42,1.83c-4.4,1.22-7.57,2.81-9.52,4.76-1.71,2.69-2.56,5.38-2.56,8.06,0,3.66,1.1,6.59,3.3,8.79,2.2,1.96,5.37,2.93,9.52,2.93,6.83,0,12.21-1.95,16.12-5.86,3.9-3.9,5.86-9.28,5.86-16.12v-9.16Z" />
                    </g>
                    <g>
                        <path className="cls-3" d="M402.61,824.29v-103.66h-37.36v-9.16c0-3.66,1.28-6.78,3.85-9.34,2.56-2.56,5.68-3.85,9.34-3.85h87.18v22.34h-37v103.66h-26.01Z" />
                        <path className="cls-3" d="M440.37,824.29v-47.25c0-9.52,1.4-17.52,4.21-23.99,2.8-6.47,6.65-11.66,11.54-15.57,4.63-3.66,10.07-6.35,16.3-8.06,6.23-1.71,12.64-2.57,19.23-2.57h3.66v26.01h-8.42c-7.09,0-12.33,1.71-15.75,5.13-3.42,3.43-5.13,8.79-5.13,16.12v50.18h-25.64Z" />
                        <path className="cls-3" d="M560.14,820.26c-5.13,2.69-10.56,4.33-16.3,4.94-5.74,.61-10.8,.92-15.2,.92-8.79,0-16.48-2.38-23.08-7.14-6.59-4.76-9.89-12.64-9.89-23.63,0-10.26,2.99-17.58,8.97-21.98,5.98-4.4,13.61-7.33,22.89-8.79,1.22,0,2.69-.18,4.4-.55,1.71-.37,3.66-.67,5.86-.92,10.74-1.47,16.12-4.63,16.12-9.52,0-3.66-1.89-6.05-5.68-7.14-3.79-1.1-7.27-1.65-10.44-1.65-3.42,0-6.47,.49-9.16,1.47-2.69,.98-4.64,2.81-5.86,5.49h-24.54c.97-7.56,4.4-13.79,10.26-18.68,6.59-5.61,15.99-8.42,28.2-8.42,13.67,0,23.93,2.32,30.77,6.96,7.08,4.89,10.62,11.72,10.62,20.51v33.33c0,9.77-1.77,17.4-5.31,22.89-3.54,5.49-7.75,9.46-12.64,11.9Zm-5.86-45.05c-3.42,1.71-8.19,3.3-14.29,4.76l-8.42,1.83c-4.4,1.22-7.57,2.81-9.52,4.76-1.71,2.69-2.56,5.38-2.56,8.06,0,3.66,1.1,6.59,3.3,8.79,2.2,1.96,5.37,2.93,9.52,2.93,6.83,0,12.21-1.95,16.12-5.86,3.9-3.9,5.86-9.28,5.86-16.12v-9.16Z" />
                        <path className="cls-3" d="M653.27,794.25h18.31c-2.69,10.26-7.82,18.08-15.38,23.45-7.57,5.62-17.1,8.42-28.57,8.42-13.92,0-24.79-4.52-32.6-13.55-7.82-8.79-11.72-21.36-11.72-37.73s3.78-27.84,11.35-36.63c7.81-9.03,18.68-13.55,32.6-13.55,14.65,0,26.01,4.4,34.06,13.19,4.4,5.37,7.57,11.6,9.52,18.68h-25.64c-1.22-2.44-2.44-4.27-3.66-5.49-3.17-3.42-7.69-5.13-13.55-5.13-5.37,0-9.77,1.71-13.18,5.13-4.4,4.89-6.59,12.95-6.59,24.17s2.2,19.17,6.59,23.81c3.17,3.91,7.93,5.86,14.29,5.86,4.15,0,7.69-.85,10.62-2.57,.73-.24,1.4-.67,2.01-1.28,.61-.61,1.28-1.28,2.01-2.01,.49-.73,1.47-1.71,2.93-2.93,1.47-1.22,3.66-1.83,6.59-1.83Z" />
                        <path className="cls-3" d="M701.25,726.86v35.9l31.13-35.9h30.77l-34.8,37.36,35.16,60.07h-30.77l-17.95-32.96c-3.66-6.59-6.84-9.4-9.52-8.43-2.69,.98-4.03,2.45-4.03,4.4v37h-25.64v-126h13.18c3.42,0,6.35,1.23,8.79,3.66,2.44,2.45,3.66,5.26,3.66,8.42v16.48Z" />
                        <path className="cls-3" d="M820.68,794.25h18.31c-2.69,10.26-7.82,18.08-15.38,23.45-7.57,5.62-17.1,8.42-28.57,8.42-13.92,0-24.79-4.52-32.6-13.55-7.82-8.79-11.72-21.36-11.72-37.73s3.78-27.84,11.35-36.63c7.81-9.03,18.68-13.55,32.6-13.55,14.65,0,26.01,4.4,34.06,13.19,7.81,9.04,11.72,21.61,11.72,37.73v4.03c0,1.22-.13,2.08-.37,2.56h-63.36c.24,7.57,2.07,13.19,5.49,16.85,3.17,3.91,7.93,5.86,14.29,5.86,4.15,0,7.69-.85,10.62-2.57,.73-.24,1.4-.67,2.01-1.28,.61-.61,1.28-1.28,2.01-2.01,.24-.73,1.16-1.71,2.75-2.93,1.59-1.22,3.85-1.83,6.78-1.83Zm-43.95-28.57h37.36c-.49-6.59-2.2-11.48-5.13-14.65-3.17-3.42-7.69-5.13-13.55-5.13-5.37,0-9.77,1.71-13.18,5.13-3.42,3.42-5.26,8.3-5.49,14.65Z" />
                        <path className="cls-3" d="M844.48,824.29v-47.25c0-9.52,1.4-17.52,4.21-23.99,2.8-6.47,6.65-11.66,11.54-15.57,4.63-3.66,10.07-6.35,16.3-8.06,6.23-1.71,12.64-2.57,19.23-2.57h3.66v26.01h-8.42c-7.09,0-12.33,1.71-15.75,5.13-3.42,3.43-5.13,8.79-5.13,16.12v50.18h-25.64Z" />
                    </g>
                </g><g>
                    <g>
                        <g>

                            <path className="cls-3" d="M722.1,452.29c-.29-58.2-47.72-105.46-105.98-105.46-35.41,0-66.78,17.47-86.04,44.22-5.15-2.02-10.44-3.66-15.82-4.93-3.43-10.77-13.52-18.59-25.41-18.59s-21.78,7.68-25.3,18.29c-5.39,1.2-10.7,2.77-15.87,4.72-19.28-26.47-50.49-43.72-85.67-43.72-58.45,0-106,47.55-106,106,0,.57,.03,1.14,.04,1.7,0,.09-.04,.16-.04,.25-.18,2.52-.13,4.87-.08,6.95,.02,.84,.04,1.69,.04,2.53,0,64.73,47.28,142.9,106,142.9s106-59.11,106-126.5v-3.16c0-14.72,11.11-18.97,21.39-18.97h.02c8.02,0,20.74,5.27,20.74,18.96v3.18c0,67.39,49.53,126.5,106,126.5s106-78.18,106-142.9c0-1.14,.04-2.28,.07-3.41,.08-2.57,.16-5.48-.09-8.56Zm-360.09,79.2c-43.37,0-78.66-35.29-78.66-78.66s35.28-78.66,78.66-78.66,78.66,35.29,78.66,78.66-35.28,78.66-78.66,78.66Zm254.11,0c-43.37,0-78.66-35.29-78.66-78.66s35.28-78.66,78.66-78.66,78.66,35.29,78.66,78.66-35.28,78.66-78.66,78.66Z" />
                            <circle className="cls-2" cx="488.85" cy="394.21" r="15.55" />
                        </g>
                        <path className="cls-3" d="M616.45,641.34c-23.74,0-46.57-8.61-64.28-24.26-3.86-3.41-4.22-9.29-.81-13.15,3.4-3.85,9.29-4.23,13.15-.81,14.3,12.63,32.75,19.59,51.94,19.59,17.45,0,35.93-7.77,52.03-21.89,3.88-3.4,9.76-3,13.15,.87,3.39,3.87,3,9.76-.87,13.15-19.51,17.1-42.34,26.51-64.31,26.51Z" />
                        <path className="cls-3" d="M361.62,641.34c-22.03,0-44.86-9.44-64.28-26.58-3.86-3.41-4.23-9.3-.82-13.15,3.41-3.86,9.29-4.23,13.15-.82,16.02,14.14,34.47,21.92,51.95,21.92,19.16,0,37.64-6.95,52.02-19.56,3.88-3.4,9.76-3,13.15,.86,3.39,3.87,3,9.76-.86,13.15-17.79,15.6-40.63,24.18-64.31,24.18Z" />
                    </g>
                    <path className="cls-1" d="M801.74,355.29c-93.23-64.17-172.63-145.37-246-232.01-31.31-36.97-55.95-55.09-89.52-12.9-17.17,21.59-35.09,43.98-55.21,66.6,.47-21.25,.01-42.32-1.49-63.55-2.48-34.97-46.13-32.17-38.37,23.18,3.49,24.86,5.12,50.55,5.39,76.83-45.83,45.4-103.67,90.48-185.38,130.69-94.7,46.61-17.57,61.68,28.91,32.3,103.17-65.21,194.66-138.24,254.12-242.45,21.24-37.23,32.5-30.52,64.44,16.14,70.45,102.9,141.93,181.97,234.33,254.37,42.02,32.93,100.71,.3,28.78-49.18Z" />
                </g>
            </g><g>

                <path className="cls-3" d="M397.11,900.5v-44.85h13.09c4.66,0,8.29,.99,10.89,2.95,2.6,1.96,3.89,4.91,3.89,8.86,0,2.31-.65,4.31-1.96,6.01-1.3,1.71-3.07,2.89-5.31,3.55,2.73,.49,4.95,1.78,6.66,3.85,1.7,2.07,2.55,4.47,2.55,7.17,0,4.01-1.3,7.08-3.9,9.24-2.6,2.16-6.07,3.23-10.42,3.23h-15.49Zm3.66-24.73h10.32c3.24,0,5.75-.72,7.53-2.15,1.78-1.42,2.66-3.53,2.66-6.29s-.94-4.97-2.83-6.39c-1.89-1.43-4.64-2.15-8.26-2.15h-9.42v16.97Zm0,3.15v18.44h11.83c3.31,0,5.9-.81,7.79-2.44,1.89-1.62,2.84-3.9,2.84-6.82,0-2.69-.86-4.88-2.57-6.6-1.71-1.73-4.21-2.58-7.47-2.58h-12.42Z" />
                <path className="cls-3" d="M444.63,891.47l1.17,3.97h.19l10.07-28.27h4.13l-14.48,38.66c-.86,2.26-1.98,4.18-3.36,5.76-1.38,1.58-3.39,2.37-6.04,2.37-.43,0-.96-.05-1.57-.14-.62-.1-1.07-.19-1.36-.3l.43-3.07c.25,.04,.64,.09,1.19,.14,.54,.05,.94,.07,1.18,.07,1.6,0,2.89-.57,3.85-1.71,.97-1.13,1.79-2.6,2.47-4.38l1.75-4.5-12.87-32.9h4.1l9.15,24.3Z" />
                <path className="cls-3" d="M508.06,889.49c0-2.44-.91-4.43-2.74-5.98-1.83-1.54-5.01-2.91-9.55-4.1-4.91-1.23-8.62-2.84-11.15-4.81-2.52-1.97-3.79-4.63-3.79-7.98s1.41-6.22,4.24-8.38c2.82-2.16,6.45-3.23,10.89-3.23s8.35,1.28,11.18,3.85c2.83,2.57,4.21,5.7,4.13,9.4l-.09,.19h-3.39c0-3-1.06-5.46-3.19-7.4-2.13-1.92-5-2.89-8.64-2.89s-6.4,.8-8.43,2.39c-2.02,1.6-3.03,3.6-3.03,5.98,0,2.26,.94,4.14,2.81,5.65,1.87,1.5,5.09,2.84,9.67,3.98,4.82,1.23,8.49,2.9,11.01,5,2.52,2.1,3.77,4.86,3.77,8.29s-1.46,6.41-4.39,8.52c-2.92,2.12-6.66,3.17-11.19,3.17s-8.38-1.12-11.72-3.37c-3.34-2.25-4.95-5.53-4.85-9.84l.06-.19h3.39c0,3.48,1.3,6.07,3.91,7.75,2.61,1.68,5.67,2.52,9.21,2.52s6.42-.78,8.61-2.34c2.19-1.55,3.28-3.61,3.28-6.18Z" />
                <path className="cls-3" d="M522.41,867.17l.34,5.51c1.07-1.95,2.49-3.45,4.27-4.53,1.78-1.06,3.9-1.6,6.36-1.6s4.68,.62,6.4,1.85c1.71,1.23,2.94,3.11,3.68,5.64,1.01-2.34,2.44-4.17,4.3-5.5,1.86-1.32,4.12-1.99,6.79-1.99,3.55,0,6.3,1.18,8.23,3.54,1.92,2.37,2.89,6.02,2.89,10.97v19.44h-3.69v-19.54c0-4.11-.73-7.02-2.17-8.72-1.45-1.69-3.47-2.54-6.06-2.54-2.85,0-5.06,.9-6.62,2.69-1.56,1.79-2.53,4.08-2.9,6.88,0,.14,0,.35,.02,.62,0,.26,.01,.46,.01,.58v20.03h-3.73v-19.54c0-4.03-.73-6.92-2.2-8.66-1.47-1.73-3.48-2.6-6.05-2.6s-4.68,.67-6.21,1.99c-1.53,1.33-2.6,3.12-3.22,5.35v23.46h-3.73v-33.33h3.29Z" />
                <path className="cls-3" d="M579.33,857.62h-3.66v-5.17h3.66v5.17Zm0,42.88h-3.66v-33.33h3.66v33.33Z" />
                <path className="cls-3" d="M593.65,900.5h-3.66v-48.05h3.66v48.05Z" />
                <path className="cls-3" d="M607.98,857.62h-3.66v-5.17h3.66v5.17Zm0,42.88h-3.66v-33.33h3.66v33.33Z" />
                <path className="cls-3" d="M621.44,867.17l.34,5.82c1.07-2.05,2.51-3.64,4.33-4.76,1.82-1.11,3.97-1.68,6.45-1.68,3.61,0,6.37,1.11,8.26,3.32,1.89,2.21,2.83,5.65,2.83,10.32v20.3h-3.69v-20.3c0-3.84-.72-6.54-2.14-8.12-1.43-1.59-3.45-2.38-6.09-2.38s-4.69,.68-6.33,2.05c-1.63,1.37-2.81,3.19-3.53,5.45v23.3h-3.69v-33.33h3.26Z" />
                <path className="cls-3" d="M651.84,884.12c0-5.33,1.13-9.58,3.4-12.77,2.27-3.19,5.43-4.8,9.47-4.8,2.36,0,4.4,.52,6.13,1.55,1.72,1.04,3.15,2.5,4.28,4.39l.43-5.33h3.11v32.9c0,4.29-1.18,7.69-3.56,10.16-2.37,2.48-5.67,3.73-9.9,3.73-1.6,0-3.31-.2-5.11-.58-1.81-.39-3.39-.93-4.74-1.6l.95-3.11c1.31,.65,2.7,1.15,4.16,1.49,1.46,.33,3.02,.51,4.68,.51,3.24,0,5.69-.94,7.35-2.8,1.65-1.88,2.48-4.47,2.48-7.8v-4.19c-1.13,1.69-2.56,2.99-4.28,3.9-1.73,.91-3.74,1.37-6.04,1.37-4,0-7.14-1.48-9.41-4.47-2.27-2.97-3.4-6.94-3.4-11.92v-.64Zm3.7,.64c0,4.02,.82,7.24,2.46,9.65,1.64,2.39,4.09,3.6,7.33,3.6,2.38,0,4.37-.56,5.96-1.65,1.59-1.1,2.82-2.62,3.68-4.54v-15.47c-.8-1.95-1.98-3.54-3.53-4.79-1.55-1.25-3.57-1.86-6.05-1.86-3.24,0-5.7,1.34-7.36,4.02-1.67,2.68-2.49,6.14-2.49,10.4v.64Z" />
                <path className="cls-3" d="M737.62,900.5h-3.66v-20.64h-25.5v20.64h-3.66v-44.85h3.66v21.07h25.5v-21.07h3.66v44.85Z" />
                <path className="cls-3" d="M746.3,883.34c0-4.91,1.34-8.93,4.04-12.08,2.69-3.13,6.25-4.71,10.69-4.71s8.02,1.58,10.71,4.71c2.7,3.15,4.04,7.17,4.04,12.08v1.02c0,4.92-1.34,8.96-4.02,12.09-2.68,3.13-6.24,4.69-10.67,4.69s-8.06-1.55-10.75-4.69c-2.7-3.13-4.04-7.17-4.04-12.09v-1.02Zm3.7,1c0,3.86,.97,7.11,2.92,9.72,1.95,2.63,4.68,3.95,8.17,3.95s6.12-1.32,8.08-3.95c1.96-2.62,2.94-5.86,2.94-9.72v-1.01c0-3.8-.98-7.02-2.95-9.66-1.97-2.65-4.68-3.97-8.13-3.97s-6.16,1.32-8.1,3.97c-1.95,2.64-2.92,5.86-2.92,9.66v1.01Z" />
                <path className="cls-3" d="M805.32,895.05c-1.07,1.95-2.5,3.45-4.31,4.51-1.81,1.06-3.95,1.58-6.41,1.58-3.51,0-6.27-1.18-8.26-3.56-1.99-2.38-2.99-6.08-2.99-11.09v-19.32h3.69v19.42c0,4.07,.7,6.99,2.08,8.77,1.39,1.76,3.36,2.65,5.9,2.65,2.79,0,5.02-.63,6.68-1.89,1.67-1.26,2.82-2.99,3.48-5.18v-23.77h3.69v33.33h-3.26l-.31-5.45Z" />
                <path className="cls-3" d="M838.56,892.01c0-1.55-.64-2.92-1.92-4.08-1.28-1.17-3.6-2.12-6.94-2.86-3.98-.86-6.93-2-8.83-3.42-1.9-1.42-2.85-3.42-2.85-6.01s1.07-4.7,3.22-6.45c2.15-1.76,4.98-2.64,8.52-2.64,3.76,0,6.74,.94,8.93,2.79,2.2,1.86,3.24,4.18,3.15,6.98l-.06,.19h-3.42c0-1.83-.78-3.42-2.33-4.77-1.55-1.36-3.64-2.04-6.27-2.04s-4.71,.58-6.05,1.73c-1.35,1.16-2.02,2.52-2.02,4.07s.58,2.8,1.75,3.8c1.16,1,3.47,1.88,6.94,2.63,3.96,.89,6.93,2.1,8.9,3.64,1.97,1.53,2.95,3.61,2.95,6.25,0,2.78-1.12,5.03-3.37,6.75-2.25,1.73-5.22,2.58-8.92,2.58-4.05,0-7.22-.96-9.54-2.89-2.31-1.94-3.4-4.23-3.28-6.91l.06-.19h3.39c.14,2.39,1.12,4.13,2.94,5.22,1.81,1.09,3.95,1.63,6.42,1.63,2.67,0,4.77-.59,6.32-1.75,1.54-1.17,2.31-2.58,2.31-4.24Z" />
                <path className="cls-3" d="M863.23,901.14c-4.17,0-7.64-1.53-10.4-4.61-2.76-3.08-4.14-6.98-4.14-11.71v-1.69c0-4.76,1.38-8.72,4.11-11.87,2.74-3.13,6.06-4.71,9.97-4.71s7.3,1.3,9.64,3.89c2.34,2.59,3.51,6.03,3.51,10.35v3.15h-23.53v.89c0,3.75,.99,6.88,2.99,9.4,1.99,2.53,4.61,3.79,7.86,3.79,2.29,0,4.26-.32,5.88-.96,1.62-.63,3.01-1.54,4.16-2.74l1.57,2.53c-1.25,1.32-2.84,2.37-4.76,3.15-1.92,.78-4.21,1.16-6.85,1.16Zm-.46-31.44c-2.73,0-5.02,1.04-6.87,3.12-1.85,2.07-2.92,4.68-3.23,7.81l.06,.16h19.53v-.93c0-2.89-.85-5.3-2.56-7.24-1.7-1.95-4.02-2.92-6.93-2.92Z" />
            </g>
        </svg>

    )
}

export default Logo