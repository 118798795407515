export const data = [
  {
    id: "9s41rp",
    firstName: "Kelvin",
    lastName: "Langosh",
    email: "Jerod14@hotmail.com",
    age: 19,
    state: "Ohio",
    phone: "+41-79-489-7021",
  },
  {
    id: "08m6rx",
    firstName: "Molly",
    lastName: "Purdy",
    email: "Hugh.Dach79@hotmail.com",
    age: 37,
    state: "Rhode Island",
    phone: "+41-79-489-7021",
  },
  {
    id: "5ymtrc",
    firstName: "Henry",
    lastName: "Lynch",
    email: "Camden.Macejkovic@yahoo.com",
    age: 20,
    state: "California",
    phone: "+41-79-489-7021",
  },
  {
    id: "ek5b97",
    firstName: "Glenda",
    lastName: "Douglas",
    email: "Eric0@yahoo.com",
    age: 38,
    state: "Montana",
    phone: "+41-79-489-7021",
  },
  {
    id: "xxtydd",
    firstName: "Leone",
    lastName: "Williamson",
    email: "Ericka_Mueller52@yahoo.com",
    age: 19,
    state: "Colorado",
    phone: "+41-79-489-7021",
  },
  {
    id: "wzxj9m",
    firstName: "Mckenna",
    lastName: "Friesen",
    email: "Veda_Feeney@yahoo.com",
    age: 34,
    state: "New York",
    phone: "+41-79-489-7021",
  },
  {
    id: "21dwtz",
    firstName: "Wyman",
    lastName: "Jast",
    email: "Melvin.Pacocha@yahoo.com",
    age: 23,
    state: "Montana",
    phone: "+41-79-489-7021",
  },
  {
    id: "o8oe4k",
    firstName: "Janick",
    lastName: "Willms",
    email: "Delfina12@gmail.com",
    age: 25,
    state: "Nebraska",
    phone: "+41-79-489-7021",
  },
  {
    id: "9s41rp",
    firstName: "Kelvin",
    lastName: "Langosh",
    email: "Jerod14@hotmail.com",
    age: 19,
    state: "Ohio",
    phone: "+41-79-489-7021",
  },
  {
    id: "08m6rx",
    firstName: "Molly",
    lastName: "Purdy",
    email: "Hugh.Dach79@hotmail.com",
    age: 37,
    state: "Rhode Island",
    phone: "+41-79-489-7021",
  },
  {
    id: "5ymtrc",
    firstName: "Henry",
    lastName: "Lynch",
    email: "Camden.Macejkovic@yahoo.com",
    age: 20,
    state: "California",
    phone: "+41-79-489-7021",
  },
  {
    id: "ek5b97",
    firstName: "Glenda",
    lastName: "Douglas",
    email: "Eric0@yahoo.com",
    age: 38,
    state: "Montana",
    phone: "+41-79-489-7021",
  },
  {
    id: "xxtydd",
    firstName: "Leone",
    lastName: "Williamson",
    email: "Ericka_Mueller52@yahoo.com",
    age: 19,
    state: "Colorado",
    phone: "+41-79-489-7021",
  },
  {
    id: "wzxj9m",
    firstName: "Mckenna",
    lastName: "Friesen",
    email: "Veda_Feeney@yahoo.com",
    age: 34,
    state: "New York",
    phone: "+41-79-489-7021",
  },
  {
    id: "21dwtz",
    firstName: "Wyman",
    lastName: "Jast",
    email: "Melvin.Pacocha@yahoo.com",
    age: 23,
    state: "Montana",
    phone: "+41-79-489-7021",
  },
  {
    id: "o8oe4k",
    firstName: "Janick",
    lastName: "Willms",
    email: "Delfina12@gmail.com",
    age: 25,
    state: "Nebraska",
    phone: "+41-79-489-7021",
  },
  {
    id: "9s41rp",
    firstName: "Kelvin",
    lastName: "Langosh",
    email: "Jerod14@hotmail.com",
    age: 19,
    state: "Ohio",
    phone: "+41-79-489-7021",
  },
  {
    id: "08m6rx",
    firstName: "Molly",
    lastName: "Purdy",
    email: "Hugh.Dach79@hotmail.com",
    age: 37,
    state: "Rhode Island",
    phone: "+41-79-489-7021",
  },
  {
    id: "5ymtrc",
    firstName: "Henry",
    lastName: "Lynch",
    email: "Camden.Macejkovic@yahoo.com",
    age: 20,
    state: "California",
    phone: "+41-79-489-7021",
  },
  {
    id: "ek5b97",
    firstName: "Glenda",
    lastName: "Douglas",
    email: "Eric0@yahoo.com",
    age: 38,
    state: "Montana",
    phone: "+41-79-489-7021",
  },
  {
    id: "xxtydd",
    firstName: "Leone",
    lastName: "Williamson",
    email: "Ericka_Mueller52@yahoo.com",
    age: 19,
    state: "Colorado",
    phone: "+41-79-489-7021",
  },
  {
    id: "wzxj9m",
    firstName: "Mckenna",
    lastName: "Friesen",
    email: "Veda_Feeney@yahoo.com",
    age: 34,
    state: "New York",
    phone: "+41-79-489-7021",
  },
  {
    id: "21dwtz",
    firstName: "Wyman",
    lastName: "Jast",
    email: "Melvin.Pacocha@yahoo.com",
    age: 23,
    state: "Montana",
    phone: "+41-79-489-7021",
  },
  {
    id: "o8oe4k",
    firstName: "Janick",
    lastName: "Willms",
    email: "Delfina12@gmail.com",
    age: 25,
    state: "Nebraska",
    phone: "+41-79-489-7021",
  },
  {
    id: "9s41rp",
    firstName: "Kelvin",
    lastName: "Langosh",
    email: "Jerod14@hotmail.com",
    age: 19,
    state: "Ohio",
    phone: "+41-79-489-7021",
  },
  {
    id: "08m6rx",
    firstName: "Molly",
    lastName: "Purdy",
    email: "Hugh.Dach79@hotmail.com",
    age: 37,
    state: "Rhode Island",
    phone: "+41-79-489-7021",
  },
  {
    id: "5ymtrc",
    firstName: "Henry",
    lastName: "Lynch",
    email: "Camden.Macejkovic@yahoo.com",
    age: 20,
    state: "California",
    phone: "+41-79-489-7021",
  },
  {
    id: "ek5b97",
    firstName: "Glenda",
    lastName: "Douglas",
    email: "Eric0@yahoo.com",
    age: 38,
    state: "Montana",
    phone: "+41-79-489-7021",
  },
  {
    id: "xxtydd",
    firstName: "Leone",
    lastName: "Williamson",
    email: "Ericka_Mueller52@yahoo.com",
    age: 19,
    state: "Colorado",
    phone: "+41-79-489-7021",
  },
  {
    id: "wzxj9m",
    firstName: "Mckenna",
    lastName: "Friesen",
    email: "Veda_Feeney@yahoo.com",
    age: 34,
    state: "New York",
    phone: "+41-79-489-7021",
  },
  {
    id: "21dwtz",
    firstName: "Wyman",
    lastName: "Jast",
    email: "Melvin.Pacocha@yahoo.com",
    age: 23,
    state: "Montana",
    phone: "+41-79-489-7021",
  },
  {
    id: "o8oe4k",
    firstName: "Janick",
    lastName: "Willms",
    email: "Delfina12@gmail.com",
    age: 25,
    state: "Nebraska",
    phone: "+41-79-489-7021",
  },
];

//50 us states array
export const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "Puerto Rico",
];

export const dummyTaxes = [
  {
    _id: "63ffb430ee0641f7a680c56c",
    accountId: "633bf9f863cddc0031122a59",
    type: "CITY_TAX",
    amount: 1,
    units: "FIXED",
    quantifier: "PER_GUEST_PER_NIGHT",
    appliedToAllFees: false,
    appliedOnFees: [],
    isAppliedByDefault: false,
    appliedByDefaultOnChannels: [],
    __v: 0,
  },
  {
    _id: "63ffb430ee0641f7a680c56d",
    accountId: "633bf9f863cddc0031122a59",
    type: "STATE_TAX",
    amount: 2,
    units: "FIXED",
    quantifier: "PER_STAY",
    appliedToAllFees: false,
    appliedOnFees: [],
    isAppliedByDefault: false,
    appliedByDefaultOnChannels: [],
    __v: 0,
  },
  {
    _id: "63ffb430ee0641f7a680c56f",
    accountId: "633bf9f863cddc0031122a59",
    type: "OTHER",
    amount: 4,
    name: "Sales",
    units: "PERCENTAGE",
    quantifier: "PER_STAY",
    appliedToAllFees: false,
    appliedOnFees: ["AF"],
    isAppliedByDefault: false,
    appliedByDefaultOnChannels: [],
    __v: 0,
  },
  {
    _id: "63ffb430ee0641f7a680c56e",
    accountId: "633bf9f863cddc0031122a59",
    type: "TOURISM_TAX",
    amount: 2,
    units: "PERCENTAGE",
    quantifier: "PER_STAY",
    appliedToAllFees: true,
    appliedOnFees: [
      "AF",
      "CF",
      "ADDITIONAL_BED",
      "AIR_CONDITIONING",
      "EARLY_CHECK_IN",
      "LATE_CHECK_IN",
      "BABY_BED",
      "CLEANING",
      "CLUB_CARD",
      "CONCIERGE",
      "EARLY_CHECKOUT",
      "LATE_CHECKOUT",
      "DEPOSIT",
      "ELECTRICITY",
      "FOOD",
      "HEATING",
      "INTERNET",
      "LAUNDRY",
      "LINENS",
      "TOWELS",
      "MANAGEMENT",
      "OIL",
      "PARKING",
      "PET",
      "POOL",
      "POOL_HEATING",
      "RESORT",
      "SERVICE",
      "TOILETRIES",
      "TOUR",
      "TRANSPORTATION",
      "CAR_RENTAL",
      "WATER",
      "WOOD",
      "TRANSFER",
      "HOUSEKEEPING",
      "INSURANCE",
      "COMMUNITY",
      "CREDIT_CARD_PROCESSING_FEE",
      "DAMAGE_WAIVER",
    ],
    isAppliedByDefault: false,
    appliedByDefaultOnChannels: [],
    __v: 0,
  },
  {
    _id: "63ffb430ee0641f7a680c570",
    accountId: "633bf9f863cddc0031122a59",
    type: "LOCAL_TAX",
    amount: 2,
    units: "FIXED",
    quantifier: "PER_GUEST_PER_NIGHT",
    appliedToAllFees: false,
    appliedOnFees: [],
    isAppliedByDefault: false,
    appliedByDefaultOnChannels: [],
    __v: 0,
  },
];
