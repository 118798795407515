import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { PATH_PROPERTY } from "../../Util/constants";
import * as propertyActions from "../../store/redux/Property/actions";

const PropertyId = (props) => {
  const {noMenu,dateFrom,dateTo,adults,children}=props
  const { id } = useParams(); // Get the property ID from the URL
  const dispatch = useDispatch();
  const history = useHistory();
if (noMenu) {console.log('Links?:',noMenu,dateFrom,dateTo,children)}
  useEffect(() => {
    const loadAndNavigate = async () => {
      console.log("Loading property with ID:", id);
      await dispatch(propertyActions.loadProperty(id)); 

      const storedProperty =localStorage.getItem("property")? JSON.parse(localStorage.getItem("property")):{};

      if (storedProperty) {
        const { listing, xdata, fullCalendar,activeRatePlan,channelSource } = storedProperty;

        if (listing && xdata && fullCalendar && activeRatePlan) {
          if (noMenu) {localStorage.setItem('noMenu',true)}
          if (dateFrom) {console.log('dateFrom',dateFrom)}
          if (dateTo) {console.log('dateTo',dateFrom)}
          if (adults) {console.log('adults',adults)}
          if (children) {console.log('children',children)}
          console.log("Property loaded:", listing, xdata, fullCalendar, activeRatePlan.id,channelSource );
          localStorage.setItem("activeRatePlan", activeRatePlan.id);
          localStorage.setItem("channelSource", channelSource);
          localStorage.setItem("propertyId", id);
          localStorage.setItem("minNights", listing.terms.minNights);

          history.replace({
            pathname: `${PATH_PROPERTY}/${id}`,
            state: {
              property: listing,
              xdata,
              fullCalendar,
              activeRatePlan:activeRatePlan.id,
              channelSource,
              noMenu:true
            },
          });
        } else {
          console.error("Incomplete property data");
        }
      } else {
        console.error("Property not found in localStorage");
      }
    };

    loadAndNavigate();
  }, [id, dispatch, history]);

  return null; // This component does not render anything
};

export default PropertyId;