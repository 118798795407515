import React from 'react';
import "./App.scss";
import MainPage from "./pages";
import ReactGA from 'react-ga4';
ReactGA.initialize('G-ERC3BXEZ3P');
function App() {
 
   return <MainPage />;
}
export default App;
