import React, { useState, useEffect } from "react";
import Button from "../../../components/Buttons/Button/Button";
import InputField from "../../../components/InputField";
import TextAreaField from "../../../components/TextAreaField";
import addTitleIcon from "../../../assets/icons/admin/add-title-icon.svg";
import editTitleIcon from "../../../assets/icons/admin/edit-title-icon.svg";
import closeIcon from "../../../assets/icons/closeIcon.png";
import "./EditAgency.scss";
import AuthService from "../../../services/auth.service";
import swal from "sweetalert";
import { log } from "loglevel";
import countryList from "../../../Util/data/countries.json";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import DropdownInput from "../../../components/Forms/Fields/DropdownInput/DropdownInput";

const EditAgency = (props) => {
  const { agency, onClose, agencies, editClickedId, editAgencyId, item } =
    props;
  useEffect(() => {
    const load = async () => {};
    load();
  }, []);
  const agencyTypes = [
    { name: 'Travel agency' },
    { name: 'Travel designer' },
    { name: 'Event planer' },
    { name: 'Concierge company' },
    { name: 'VIP company' },
    { name: 'Personal assistant of an executive' },
    { name: 'Retreat facilitator' },
  ];
  // console.log(agencies, "agencies");
  const [filterDataSingle, setfilterDataSingle] = useState(
    agencies.filter((iteam) => iteam._id === agency)
  );
  console.log(item);
  const [agencyName, setAgencyName] = useState(
    item?.agencyDetails[0].agencyName
  );
  const [agencyType, setAgencyType] = useState(
    item?.agencyDetails[0].agencyType
  );
  const [agentName, setAgentName] = useState(item?.agencyDetails[0]?.firstName);
  const [address, setAddress] = useState(item?.agencyDetails[0]?.address);
  const [email, setEmail] = useState(item?.agencyDetails[0]?.email);
  const getCountry = (countryCode) => {
    const ccIndex = countryList.findIndex((i) => i.code === countryCode);
    const cc = ccIndex ? countryList[ccIndex]?.name : "";
    console.log("countryName=", cc);
    return cc;
  };

  const [countryCode, setCountryCode] = useState(
    item?.agencyDetails[0]?.countryCode
  );
  const [country, setCountry] = useState(item?.agencyDetails[0]?.country);
  const [phone, setPhone] = useState(item?.agencyDetails[0]?.phone);
  const [city, setCity] = useState(item?.agencyDetails[0]?.city);
  const [notes, setNotes] = useState(item?.agencyDetails[0]?.notes);

  const getCountryCode = (countryName) => {
    const ccIndex = countryList.findIndex((i) => i.name === countryName);
    const cc = ccIndex ? countryList[ccIndex]?.code : "";
    console.log("countryCode=", cc);
    return cc;
  };

  const handleCountry = (e) => {
    setCountry(e.target.value);
    setCountryCode(getCountryCode(e.target.value));
  };
  const handleSaveButton = () => {
    if (editClickedId) {
      var UpdatePayLoad = {
        agencyName: agencyName,
        agencyType: agencyType,
        firstName: agentName,
        phone: phone,
        email: email,
        address: address,
        city: city,
        countryCode: countryCode,
        country: country,
        notes: notes,
        // "firstName": "string",
        // "abbreviation": "string",
        // "website": "string",
        // "state_province": "string",
        // "organization": "string",
        // "userImage": "string",
        // "ta_company_markup": "string"
      };
      AuthService.updateTravelAgency(UpdatePayLoad, editAgencyId)
        .then((response) => {
          console.log(response.message);
          swal({
            show: true,
            icon: "success",
            title: "Success",
            text: response.message,
            timer: 3000,
          });
          setTimeout(() => {
            onClose()();
          }, 1000);
        })
        .catch((e) => {
          swal({
            show: true,
            icon: "error",
            title: "Opps!!",
            text: e.response.data.message,
            timer: 3000,
          });
        });
    } else {
      var addPayLoad = {
        agencyName: agencyName,
        agencyType: agencyType,
        firstName: agentName,
        address: address,
        countryCode: countryCode,
        country: country,
        city: city,
        phone: phone,
        email: email,
        notes: notes,
        // "firstName": "string",
        // "lastName": "string",
        // "stateProvince": "string",
        // "currency": "string",
        // "organization": "string",
        // "password": "string"
      };
      AuthService.AgentSignup(addPayLoad)
        .then((response) => {
          console.log(response.message);
          swal({
            show: true,
            icon: "success",
            title: "Success",
            text: response.message,
            timer: 3000,
          });
          setTimeout(() => {
            onClose()();
          }, 1000);
        })
        .catch((e) => {
          console.log(e, "jsdjsdjsjdk");

          swal({
            show: true,
            icon: "error",
            title: "Opps!!",
            text: e.response.data.message,
            timer: 3000,
          });
        });
    }
  };

  const handleResetButton = () => {
    if (editClickedId) {
      var resetPayLoad = {
        email: email,
      };

      AuthService.AdminReset(resetPayLoad)
        .then((response) => {
          console.log(response.message);
          swal({
            show: true,
            icon: "success",
            title: "Success",
            text: response.message,
            timer: 3000,
          });
          setTimeout(() => {
            onClose()();
          }, 1000);
        })
        .catch((e) => {
          console.log(e, "jsdjsdjsjdk");

          swal({
            show: true,
            icon: "error",
            title: "Opps!!",
            text: e.response.data.message,
            timer: 3000,
          });
        });
    }
  };

  return (
    <>
      <img src={closeIcon} className="popup-close-icon" onClick={onClose} alt='edit icon' />
      <div className="container edit-agency-container">
        <div className="edit-agency-header">
          <img src={agency.id === "-1" ? addTitleIcon : editTitleIcon} alt="" />
          <div className="edit-agency-title">
            <h3>
              {" "}
              {agency.id === "-1"
                ? "Add Agency & Manager agent details"
                : "Edit Agency/agent details"}
            </h3>
          </div>
        </div>
        <div className="edit-agency-main" style={{ display: "block" }}>
          <div className="row">
          <div className="col-md-6 col-12">
              <InputField
                label="Agency Name*"
                value={agencyName}
                onChange={setAgencyName}
                placeholder={"Enter agency name"}
                style={{ marginTop: "20px" }}
              />
            </div>
            <div className="col-md-6 col-12" style={{ marginTop: "-10px" }}>    
            <DropdownInput
              label="Travel Agency Type*"
              name="agencyType"
              showLoginLink="signup"
              error={null}
              placeholder={"choose Agency Type"}
              dropDownObj={agencyTypes}
              value={agencyType}
              setValue={setAgencyType}
            />
            </div>
            <div className="col-md-6 col-12">
              <InputField
                label="Agent Name*"
                value={agentName}
                onChange={setAgentName}
                placeholder={"your agent name"}
                style={{ marginTop: "20px" }}
              />
            </div>
            <div className="col-md-6 col-12">
              <InputField
                label="Address"
                value={address}
                onChange={setAddress}
                placeholder={"Enter address"}
                style={{ marginTop: "20px" }}
              />
            </div>
            <div className="col-md-6 col-12">
              <InputField
                label="E-mail Address*"
                value={email}
                onChange={setEmail}
                placeholder={"Enter email address"}
                style={{ marginTop: "20px" }}
              />
            </div>

            <div className="col-md-6 col-12 mt-3">
              <label
                htmlFor="country"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#707070",
                }}
              >
                Country
              </label>
              <br />
              <select
                className="form-select form-control"
                id="country"
                value={country}
                onChange={(e) => handleCountry(e)}
              >
                <option value="">Select a country</option>
                {countryList.map((countryData) => (
                  <option key={countryData.code} value={countryData.name}>
                    {countryData.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6 col-12 mt-3">
              <div class="NameInput_input_wrapper__Pg6NQ -fif_wrp">
                <div class="NameInput_label_wrapper__tE+0L -fif_label_wrp">
                  <label htmlFor="PhoneNo">Phone No*</label>
                </div>
                <div class="NameInput_input_field_wrapper__B1t2f -fif_input_wrp NameInput_error__ctrlq">
                  <PhoneInput
                    name="phone"
                    class="form-control NameInput_input_field__p8DEW"
                    country={"ch"}
                    enableSearch={true}
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={setPhone}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 col-12">
              <InputField
                label="City"
                value={city}
                onChange={setCity}
                placeholder={"Enter city"}
                style={{ marginTop: "20px" }}
              />{" "}
            </div>
            <div className="col-md-6 col-12 mb-5">
              <TextAreaField
                label="Notes"
                onChange={setNotes}
                placeholder={"Enter notes"}
                style={{ height: "152px", marginTop: "20px" }}
              >
                {notes}
              </TextAreaField>
            </div>
          </div>
        </div>
        <div className="edit-agency-footer">
          <Button
            style={{ fontSize: "18px", marginRight: "30px" }}
            variant="link"
            text="Cancel"
            onClick={onClose}
          />
          <Button
            style={{ fontSize: "18px" }}
            text="Save"
            onClick={handleSaveButton}
          />
        </div>
      </div>

      <div className="popup-wrapper">
        <div class="modal" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-lg ">
            <div class="modal-content">
              <div class="modal-header">
                <h3
                  className="text-center page-title"
                  style={{ marginLeft: "auto", marginBottom: "-10px" }}
                >
                  <img
                    src={agency.id === "-1" ? addTitleIcon : editTitleIcon}
                    alt="image"
                  />
                  {agency.id === "-1"
                    ? " Add Agency & Manager"
                    : " Edit Agency  & Manager"}
                </h3>
                <button
                  type="button"
                  style={{ marginTop: "-32px", padding: "unset" }}
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={props.onClose}
                ></button>
              </div>
              <hr></hr>

              <div class="modal-body" style={{ marginTop: "-30px" }}>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Agency Name*"
                      value={agencyName}
                      onChange={setAgencyName}
                      placeholder={"Enter agency name"}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                  <div className="col-md-6 col-12" style={{ marginTop: "10px" }}>    
            <DropdownInput
              label="Travel Agency Type*"
              name="agencyType"
              showLoginLink="signup"
              error={null}
              placeholder={"choose Agency Type"}
              dropDownObj={agencyTypes}
              value={agencyType}
              setValue={setAgencyType}
            />
            </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="Agent Name*"
                      value={agentName}
                      onChange={setAgentName}
                      placeholder={"select"}
                      style={{ marginTop: "20px" }}
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <InputField
                      label="Address"
                      value={address}
                      onChange={setAddress}
                      placeholder={"Enter address"}
                      style={{ marginTop: "20px" }}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <InputField
                      label="E-mail Address*"
                      value={email}
                      onChange={setEmail}
                      placeholder={"Enter email address"}
                      style={{ marginTop: "20px" }}
                    />
                  </div>

                  <div className="col-md-6 col-12 mt-3" style={{ marginTop: "-10px" }}>
                    <label
                      htmlFor="country"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#707070",
                      }}
                    >
                      Country
                    </label>
                    <br />
                    <select
                      className="form-select form-control"
                      id="country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="">Select a country</option>
                      {countryList.map((countryData) => (
                        <option key={countryData.code} value={countryData.name}>
                          {countryData.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 col-12 mt-1">
                    <div class="NameInput_input_wrapper__Pg6NQ -fif_wrp">
                      <div class="NameInput_label_wrapper__tE+0L -fif_label_wrp">
                        <label htmlFor="PhoneNo">Phone No*</label>
                      </div>
                      <div class="NameInput_input_field_wrapper__B1t2f -fif_input_wrp NameInput_error__ctrlq">
                        <PhoneInput
                          name="phone"
                          class="form-control NameInput_input_field__p8DEW"
                          country={"ch"}
                          enableSearch={true}
                          placeholder="Enter phone number"
                          value={phone}
                          onChange={setPhone}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <InputField
                      label="City"
                      value={city}
                      onChange={setCity}
                      placeholder={"Enter city"}
                      style={{ marginTop: "20px" }}
                    />{" "}
                  </div>
                  <div className="col-md-6 col-12 mb-5">
                    <TextAreaField
                      label="Notes"
                      onChange={setNotes}
                      placeholder={"Enter notes"}
                      style={{ height: "152px", marginTop: "20px" }}
                    >
                      {notes}
                    </TextAreaField>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <hr></hr>
                {agency.id !== "-1" &&
                  item?.agencyDetails[0]?.status !== "pending" && (
                    <Button
                      style={{ fontSize: "18px", marginRight: "30px" }}
                      variant="green"
                      text="Reset password to 'VT2024'"
                      onClick={handleResetButton}
                    />
                  )}
                <Button
                  style={{ fontSize: "18px", marginRight: "30px" }}
                  variant="link"
                  text="Cancel"
                  onClick={onClose}
                />
                <Button
                  style={{ fontSize: "18px" }}
                  text="Save"
                  onClick={handleSaveButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAgency;
