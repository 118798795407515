import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import "./Link.scss";

import { UseCreateObject } from "../../Hooks/UseCreateObject.jsx";
import getHouseRules from "../../Hooks/getHouseRules.jsx";
import { baseURL } from "../../core/index.js";
import axios from "axios";
import LinesEllipsis from "react-lines-ellipsis";
import numeral from "numeral";
import DatePickerComponent from "../../components/Forms/Fields/DatePickerComponent/DatePickerComponent.jsx";
import countryList from "../../Util/data/countries.json";
import { userRequest } from "../../api/requestMethods.js";
import SaveSearchPopup from "../../components/SelectedProperties/SaveSearchPopup/index.js";
import { log } from "loglevel";
import * as propertyActions from "../../store/redux/Property/actions.js";
import { toast, ToastContainer } from "react-toastify";
import {
  calculateTotalNights,
  countWeekendDays,
  detectCurrency,
  getStorageValue,
  isPercentage,
  isPercentageOrAmount,
  isNullOrEmptyArray,
} from "../../Util/general.js";
import { PATH_PROPERTY } from "../../Util/constants";
const Link = (props) => {
  const loggedRef = useRef(false); // Initialize the useRef hook

  if (!loggedRef.current) {
    loggedRef.current = true; // Set ref to true after logging
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const property = location?.state && location?.state?.property;
  const xdata = location?.state && location?.state?.xdata;
  const fullCalendar = location?.state && location?.state?.fullCalendar;
  const noMenu = location?.state && location?.state?.noMenu;
  const activeRatePlan = location?.state && location?.state?.activeRatePlan;
  const channelSource = location?.state && location?.state?.channelSource;
  const [errors, setErrors] = useState([]);
  // const ratePlan = location?.state && location?.state?.ratePlan;
  // const bookingLimits = location?.state && location?.state?.bookingLimits;
  const blocked = fullCalendar
    ? fullCalendar.filter((element) => element?.allotment === 0)
    : [];
  const blockedDates = blocked.map((x) => x.date.substring(0, 10));
  const [startDate, setStartDate] = useState(
    getStorageValue("dateFrom") || null
  );

  const dateFrom = getStorageValue("dateFrom");
  const dateTo = getStorageValue("dateTo");
  const ref = React.createRef();
  const currencySymbol = countryList.find(({ currency }) => {
    return currency.code === property?.prices?.currency;
  });

  const [defaultPrice, setDefaultPrice] = useState(true);
  const calculatedAmount = location?.state?.price;
  const selectedNights = location?.state?.nights;
  const searchParams = new URLSearchParams(location.search);
  const isBookConfirmed = searchParams.get("confirmed") ? true : false;
  const [modalIsOpen, setModalIsOpen] = useState(false);


  useEffect(() => {
    const fetchLinkData = async () => {
      setLoading(true);
  
      try {
        const pathSegments = location.pathname.split("/");
        const linkId = pathSegments[pathSegments.length - 1];
  
        const response = await axios.get(`${baseURL}/pdf/get-links-email/${linkId}`);
  
        if (response.data && response.data.propertyId) {
          const propertyId = response.data.propertyId;
         
          localStorage.setItem("propertyId", propertyId);
          localStorage.setItem("children", response.data.children);
          localStorage.setItem("dateTo", response.data.dateTo);
          localStorage.setItem("dateFrom", response.data.dateFrom);
          localStorage.setItem("adults", response.data.adults);
          localStorage.setItem("property", JSON.stringify(response.data.property));
            
            
          history.replace({
            pathname: `${PATH_PROPERTY}/${linkId}`,
            state: {
              noMenu: true,
            },
          });
        }
      } catch (error) {
        console.error("Email Error:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchLinkData();
  }, [location, history]);
  

  return (
    <div>
      {loading ? <p>Loading...</p> : null}
    </div>
  );
};

export default Link;
