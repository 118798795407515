import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import bathsIcon from "../../../assets/property/baths.png";
import picLeft from "../../../assets/property/pic-left.svg";
import picLeftOn from "../../../assets/property/pic-left-on.svg";
import picRight from "../../../assets/property/pic-right.svg";
import picRightOn from "../../../assets/property/pic-right-on.svg";
import likeFull from "../../../assets/icons/like-full.png";
import favoriteIcon from "../../../assets/icons/favorite.png";
import bedsIcon from "../../../assets/property/beds.png";
import peopleIcon from "../../../assets/property/people.png";
import mapIcon from "../../../assets/property/map-pin.svg";
import downloadPdf from "../../../assets/property/download-pdf-icon.svg";
import eventsIcon from "../../../assets/collections/icons/events.png";
import familyIcon from "../../../assets/collections/icons/family.png";
import petsIcon from "../../../assets/collections/icons/pets.png";
import sustainIcon from "../../../assets/collections/icons/sustainable.png";
import selectedProperty from "../../../assets/property/selected.png";
import unselectedProperty from "../../../assets/property/unselected.png";
import numeral from "numeral";
import "./PropertyBox.scss";
import {
  isNullOrEmptyArray,
  detectCurrency,
  isPercentageOrAmount,
  isPercentage, // Ensure this is imported
} from "../../../Util/general";
import ImageWithHover from "../../../components/ImageWithHover";
import constants, { PATH_PROPERTY } from "../../../Util/constants";
import axios from "axios";
import

useCalculations from "../../../Hooks/useCalculations.jsx";
import dayjs from "dayjs";
import countryList from "../../../Util/data/countries.json";

const PropertyBox = (props) => {
  const villaParamProps = props.villaParamProps;
  const [picIndex, setPicIndex] = useState(0);
  const {
    villa,
    agent,
    agency,
    property,
    xdata,
    fullCalendar,
    ratePlan,
    bookingLimits,
    selected,
    favorited,
    dontSelect,
    onToggle,
    arrivalDate, // Passed from SearchProperty
    departDate,
    onDemand,
    selectedCurrency,
    setSelectedCurrency,
    updatePrices
  } = props;
  const storedArrivalDate = localStorage.getItem("dateFrom");
  const storedDepartDate = localStorage.getItem("dateTo");

  const price = useCalculations({
    property,
    fullCalendar,
    dateFrom: localStorage.getItem("dateFrom"),
    dateTo: localStorage.getItem("dateTo"),
    children: parseInt(localStorage.getItem("children") || "0"),
    adults: parseInt(localStorage.getItem("adults") || "1"),
    currency: agency?.currency || "USD",
  });

  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [count, setCount] = useState(parseInt(localStorage.getItem("count") || 0)); // Defined count state
  const [mapOpened, setMapOpened] = useState(false); // Defined mapOpened state

  const [calculatedAgencyCommission, setCalculatedAgencyCommission] =
    useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [calculatedBasePrice, setCalculatedBasePrice] = useState(0);
  const [startDate, setStartDate] = useState(
    getStorageValue("dateFrom") || null
  );
  const [endDate, setEndDate] = useState(getStorageValue("dateTo") || null);


  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [currency, setCurrency] = useState("USD");
  const [favorites, setFavorites] = useState(favorited);
  const [pdf, setPdf] = useState(true);
  const [select, selectToggle] = useState(selected);
  const history = useHistory();
  const { prices } = property;
  const location = useLocation();
  const [refreshPrice, setRefreshPrice] = useState(0);
  // const [arrivalDate, setArrivalDate] = useState("");
  // const [departDate, setDepartDate] = useState("");
  const screen = getStorageValue("screen") || "";
  const [defaultPrice, setDefaultPrice] = useState(true);
  const [screenSize, setScreenSize] = useState(
    getStorageValue("screenSize", 0)
  );
  const [exchangeRate, setExchangeRate] = useState(1);
  const calculatedAmount = location?.state?.price;
  const selectedNights = location?.state?.nights;
  // const [selectedCurrency, setSelectedCurrency] = useState("");

  // const { price } = useCalculations({
  //   property,
  //   fullCalendar,
  //   dateFrom: arrivalDate,
  //   dateTo: departDate,
  //   adults: parseInt(localStorage.getItem("adults") || "0"),
  //   children: parseInt(localStorage.getItem("children") || "0"),
  //   currency: selectedCurrency?.currency_code || "USD",
  // });  

  // useEffect(() => {
  //   setTotalPrice(price.totalAmount || 20);
  //   setCalculatedBasePrice(price.totalAmount || 20)
  // }, [price]);

  // useEffect(() => {
  //   setTotalPrice(price.totalAmount || 'NA');
  //   setCalculatedBasePrice(price.totalAmount || 'NA')
  // }, [price]);

  useEffect(() => {
    setRefreshPrice(price.totalAmount + price.totalTaxes);
  }, [price, arrivalDate, departDate]);

  useEffect(() => {
    const agentInfo = JSON.parse(localStorage.getItem("agent") || "{}");
    const agentCurrency = agentInfo.currency || "";

    if (agentCurrency && selectedCurrency) {
      setSelectedCurrency(agentCurrency);
    }
    // console.log('selectedCurrency', agentCurrency, agentInfo, selectedCurrency);
  }, [agent, selectedCurrency, setSelectedCurrency]);

  useEffect(() => {
    if (arrivalDate && departDate) {
      setTotalPrice(price.totalAmount || 0);
      setCalculatedBasePrice(price.totalAmount || 0);
      setRefreshPrice(price.totalAmount + price.totalTaxes);
    }
  }, [price, arrivalDate, departDate]);


  useEffect(() => {
    const agentInfo = JSON.parse(localStorage.getItem("agent") || "{}");
    const agentCurrency = agentInfo.currency || "";
    const handleResize = () => {
      const size = window.innerWidth;
      localStorage.setItem("screenSize", size);
      setScreenSize(size);
    };

    window.addEventListener("resize", handleResize);

    const handleStorageChange = (e) => {
      if (e.key === "screenSize") {
        setScreenSize(parseInt(e.newValue, 10));
      }
    };
    localStorage.removeItem("clientEmail");
    window.addEventListener("storage", handleStorageChange);
    localStorage.setItem('currency', agentCurrency);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);



  useEffect(() => {
    const currency = fullCalendar?.[0]?.currency || "USD";
    if (currency !== "USD") {
      const exchangeRates = JSON.parse(localStorage.getItem("exchange") || "[]");
      const selectedExchangeRate = exchangeRates.find(
        (rate) => rate.currency_code === currency
      )?.conversion_rates;

      if (selectedExchangeRate) {
        setExchangeRate(selectedExchangeRate); // Set the exchange rate
      }
    }
  }, [fullCalendar]);

  useEffect(() => {
    if (arrivalDate && departDate) {
      setRefreshPrice(price.totalAmount + price.totalTaxes);
      setTotalPrice(price.totalAmount || 0);
    } else {
      setRefreshPrice(0);
      setTotalPrice(0);
    }
  }, [arrivalDate, departDate, price.totalAmount, price.totalTaxes]);


  useEffect(() => {
    const storedArrivalDate = localStorage.getItem("dateFrom");
    const storedDepartDate = localStorage.getItem("dateTo");

    if (storedArrivalDate && storedDepartDate) {
      const exchangeRates = JSON.parse(localStorage.getItem("exchange") || "[]");
      const selectedCurrency = agent?.currency || "USD";
      const exchangeRate = exchangeRates.find(
        (rate) => rate.currency_code === selectedCurrency
      )?.conversion_rates || 1;

      const newRefreshPrice = (price?.totalAmount + price?.totalTaxes) * exchangeRate;
      setRefreshPrice(newRefreshPrice);
      // add the refreshedPrice to selectedPrices if the property is (selected)
      if (selected) {
        updatePrices({ propertyId: property._id, prices: price })
        console.log(property._id, price);
        
      }
      // localStorage.setItem(`refreshPrice_${property._id}`, newRefreshPrice);
    } else {
      setRefreshPrice(0);
      localStorage.setItem(`refreshPrice_${property?._id}`, 0);
    }
  }, [price.totalAmount, price.totalTaxes, defaultPrice, exchangeRate]);


  // useEffect(() => {
  //   const storedArrivalDate = localStorage.getItem("dateFrom");
  //   const storedDepartDate = localStorage.getItem("dateTo");

  //   // setArrivalDate(storedArrivalDate ? new Date(storedArrivalDate) : "");
  //   // setDepartDate(storedDepartDate ? new Date(storedDepartDate) : "");

  //   if (storedArrivalDate && storedDepartDate) {
  //     const exchangeRates = JSON.parse(localStorage.getItem("exchange") || "[]");
  //     const selectedCurrency = agent?.currency || "USD";
  //     const exchangeRate = exchangeRates.find(
  //       (rate) => rate.currency_code === selectedCurrency
  //     )?.conversion_rates || 1;

  //     const newRefreshPrice = (price.totalAmount + price.totalTaxes) * exchangeRate;
  //     setRefreshPrice(newRefreshPrice);
  //     // localStorage.setItem(`refreshPrice_${property._id}`, newRefreshPrice);
  //   } else {
  //     setRefreshPrice(0);
  //     // localStorage.setItem(`refreshPrice_${property._id}`, 0);
  //   }
  // }, [price?.totalAmount, price?.totalTaxes, agent?.currency]);

  useEffect(() => {
    if (localStorage.getItem('dateFrom') && localStorage.getItem('dateTo')) {
      setRefreshPrice(price.totalAmount + price.totalTaxes);
    } else {
      setRefreshPrice(0);
    }
  }, [
    price?.totalAmount,
    price?.totalTaxes,
  ]);

  useEffect(() => {
    const load = async () => {
      // any additional logic for loading can be added here
    };
    load();
  }, [villa]);

  const token = localStorage.getItem("jToken");
  const baseURL = constants.BASE_URL; // Define your base URL here
  const userRequest = axios.create({
    baseURL: baseURL,
    headers: {
      token: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    const agentInfo = JSON.parse(localStorage.getItem("agent") || "{}");
    const agentCurrency = agentInfo.currency || "USD";
    const exchangeRates = JSON.parse(localStorage.getItem("exchange") || "[]");
    setCurrency(agentCurrency)
    if (agentCurrency !== "USD") {
      const selectedExchangeRate = exchangeRates.find(
        (rate) => rate.currency_code === agentCurrency
      )?.conversion_rates;

      if (selectedExchangeRate) {
        setExchangeRate(selectedExchangeRate);
        setCurrencySymbol(detectCurrency(agentCurrency));
      }
    } else {
      setExchangeRate(1);
      setCurrencySymbol("$");
    }
  }, []);

  const savePricesAndToggle = () => {
    if (property?._id && price) {
      console.log('propertyId and price:', property._id, price);  // These are defined
      onToggle(property);
      updatePrices(property._id, price);  // Ensure these arguments are passed correctly
    } else {
      console.error('Missing property._id or price');
    }
  };
  
 

  const favoriteAdd = async (propertyId) => {
    const agentID = localStorage.getItem("agent_id");

    const favoritesResponse = await userRequest.post(
      `/favorite/add-favorite?agent_id=${agentID}&property_id=${propertyId}`
    );
  };

  function getStorageValue(key, defaultValue) {
    const stored = localStorage.getItem(key);
    return stored ? parseInt(stored, 10) : defaultValue;
  }

  const favoriteRemove = async (propertyId) => {
    const agentID = localStorage.getItem("agent_id");
    const favoritesResponse = await userRequest.post(
      `/favorite/remove-favorite?agent_id=${agentID}&property_id=${propertyId}`
    )
    console.log(favoritesResponse)
  };

  const hotDestinationAdd = async (location) => {
    const { destination = "", country } = location
    const HotDestResponse = await userRequest.post(
      `/hotdestination/add-one-like?country=${country}&destination=${destination}`
    )
    console.log(HotDestResponse)
  };

  const hotDestinationDec = async (location) => {
    const { destination = "", country } = location
    const HotDestResponse = await userRequest.post(
      `/hotdestination/remove-one-like?country=${country}&destination=${destination}`
    )
    console.log(HotDestResponse)
  };

  const isOnDemand = (property) => {
    if (property) {
      if (property.tags) {
        if (Array.isArray(property.tags)) {
          if (property.tags.includes("onDemand")) {
            return true;
          }
        } else {
          return false;
        }
      }
    }
    return false;
  };

  const isSH = (property) => {
    return property._id?.substring(0, 2) === 'sh'
  };

  const loadFavorites = () => {
    const savedFavorites = JSON.parse(localStorage.getItem("favorites"));
    if (savedFavorites) {
      setFavorites(savedFavorites);
    }
  };

  const toggleFavorites = () => {
    const updatedFavorites = !favorites;
    setFavorites(updatedFavorites);

    const favoritePropertyIds =
      (localStorage.getItem("favoritePropertyIds") &&
        JSON.parse(localStorage.getItem("favoritePropertyIds"))) ||
      [];

    if (updatedFavorites) {
      if (!favoritePropertyIds.includes(property._id)) {
        favoritePropertyIds.push(property._id);
        localStorage.setItem(
          "favoritePropertyIds",
          JSON.stringify(favoritePropertyIds)
        );
        favoriteAdd(property._id);
        hotDestinationAdd({ destination: xdata?.region, country: xdata?.country });
      }
    } else {
      const index = favoritePropertyIds.indexOf(property._id);
      if (index > -1) {
        favoritePropertyIds.splice(index, 1);
        localStorage.setItem(
          "favoritePropertyIds",
          JSON.stringify(favoritePropertyIds)
        );
        favoriteRemove(property._id);
        hotDestinationDec({ destination: xdata?.region, country: xdata?.country });
      }
    }
  };

  useEffect(() => {
    loadFavorites();
  }, []);

  const favoritePropertyIds =
    (localStorage.getItem("favoritePropertyIds") &&
      JSON.parse(localStorage.getItem("favoritePropertyIds"))) ||
    [];
  const isFavorite = favoritePropertyIds.includes(property._id);

  let renderedIcons = 0;

  const renderLimitedCollectionIcon = (collectionType, icon) => {
    if (renderedIcons < 2) {
      renderedIcons++;
      return renderCollectionIcon(collectionType, icon);
    } else {
      return null;
    }
  };

  const renderCollectionIcon = (collection, icon) => {
    return (
      <div className="property-box-footer-left-icon-small">
        <img src={xdata?.tags?.indexOf(collection) > -1 ? icon : ""} />
      </div>
    );
  };

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const renderAdditionalFee = (text, value, symbol) => {
    return (
      <div className="d-flex justify-content-between">
        <div className="h5">{text}</div>
        <div className="h5">
          {value}
          {symbol}
        </div>
      </div>
    );
  };

  const formatTaxName = (tax) => {
    let name =
      tax?.type === "OTHER"
        ? `${tax?.name} tax`
        : tax.type?.split("_").join(" ");
    return name?.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  const getAdditionalFee = (key, value, calculateTax, totalPrice) => {
    let taxes = property?.taxes?.length > 0 ? property?.taxes : [];
    if (key === "cleaningFee" && property?.prices?.cleaningFee) {
      return renderAdditionalFee(
        "Cleaning Fee",
        property?.prices?.cleaningFee,
        detectCurrency(property?.prices?.currency)
      );
    }
    if (key === "securityDeposit" && property?.prices?.securityDepositFee) {
      return renderAdditionalFee(
        "Security Deposit",
        property?.prices?.securityDepositFee,
        detectCurrency(property?.prices?.currency)
      );
    }
    if (key === "taxes" && taxes.length > 0) {
      return taxes.map((tax) => {
        return renderAdditionalFee(
          formatTaxName(tax),
          tax?.amount,
          isPercentageOrAmount(tax?.units, property?.prices?.currency)
        );
      });
    }
    if (calculateTax) {
      const getTaxPercentage = (amount) => (totalPrice * amount) / 100;
      const getTaxAmount = (obj) => {
        let taxAmount;
        switch (obj.quantifier) {
          case "PER_STAY":
            taxAmount = obj.amount;
            break;
          case "PER_GUEST":
            taxAmount = obj.amount * property?.accommodates;
            break;
          case "PER_NIGHT":
            taxAmount =
              obj?.amount *
              (selectedNights ? selectedNights : calculateTotalNights());
            break;
          case "PER_GUEST_PER_NIGHT":
            taxAmount =
              obj.amount *
              property?.accommodates *
              (selectedNights ? selectedNights : calculateTotalNights());
            break;
          default:
            break;
        }
        return taxAmount;
      };

      return taxes?.reduce((acc, curr) => {
        const taxAmount = isPercentage(curr?.units)
          ? getTaxPercentage(curr?.amount)
          : getTaxAmount(curr);
        return acc + taxAmount;
      }, 0);
    }
  };

  const containsDate = (day, start, end) => {
    const dayIs = dayjs(day);
    return !dayIs.isBefore(dayjs(start)) && dayIs.isBefore(dayjs(end));
  };

  const BlockedInRange = (day, periods) => {
    let result = false;
    const dayIs = dayjs(day);
    if (periods) {
      periods
        .filter((period) => period.bookingLimit === 0)
        .forEach((element) => {
          result =
            result ||
            (containsDate(day, element.start, element.end) &&
              !element.bookingLimit);
        });
    }
    return result;
  };

  const getCurrencyDisplaySymbol = (currencyCode) => {
    const country = countryList.find(
      (country) => country.currency.code === currencyCode
    );
    if (country) {
      const { code, symbol } = country.currency;
      return `${symbol} `;
    }
    return currencyCode;
  };

  const PriceInRange = (day, periods) => {
    let result = 0;
    const dayIs = dayjs(day);
    if (periods) {
      periods
        .filter((period) => containsDate(day, period.start, period.end))
        .forEach((element) => {
          result = element.price;
        });
    }
    return result;
  };

  const shouldDisableDate = (day) => {
    const today = dayjs();
    if (day.isBefore(today, "day") || BlockedInRange(day, bookingLimits)) {
      return true;
    }
    return false;
  };

  const calculateTotalNights = () => {
    const nights = dayjs(departDate).diff(dayjs(arrivalDate), "day");
    return nights;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}.${month}.${day}`;
  };

  const showProperty = () => {
    localStorage.setItem("propertyId", property._id);
    localStorage.setItem("minNights", property.terms.minNights);
    history.push(`${PATH_PROPERTY}/${property._id}`, {
      property,
      xdata,
      fullCalendar,
      agency,
      agent,
      totalPrice,
      agencyCommision: totalPrice / 10,
      currency,
      exchangeRate
    });
    const agentInfo = JSON.parse(localStorage.getItem("agent") || "{}");
    agentInfo.currency = localStorage.getItem('currency');
    localStorage.setItem("agent", JSON.stringify(agentInfo));
  };

  let pic = null;

  const setNextPic = () => {
    setPicIndex(picIndex + 1);
  };

  const setPrevPic = () => {
    let p = picIndex - 1;
    if (p < 0) {
      p += property.pictures.length;
    }
    setPicIndex(p);
  };

  if (!isNullOrEmptyArray(property.pictures)) {
    pic = property.pictures[picIndex % property.pictures.length].original;
  }

  const regularProperties = [];
  const onDemandProperties = [];
  if (property) {
    if (isOnDemand(property)) {
      onDemandProperties.push(property);
    } else {
      regularProperties.push(property);
    }
  }


  return (
    <div
      className={`property-box-wrapper ${!villaParamProps && count > 2 && screenSize > 1560
        ? "col-xxl-4"
        : "col-lg-6"
        } col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12`}
      style={{
        padding: "20px",
        paddingTop: 0,
        overflow: villaParamProps ? "auto" : "hidden",
      }}
    >
      <div
        className="property-box-container"
        style={{
          backgroundImage: pic ? `url(${pic})` : "transparent",
          backgroundSize: "cover",
          height: "300px",
          maxWidth: "100%",
        }}
      >
        <ImageWithHover
          path={picLeft}
          pathOver={picLeftOn}
          className="property-box-prev-next-pic"
          style={{ left: "10px" }}
          onClick={setPrevPic}
        />
        <div
          className="property-box-center"
          onClick={() => showProperty(property._id)}
        />
        <ImageWithHover
          path={picRight}
          pathOver={picRightOn}
          className="property-box-prev-next-pic"
          style={{ right: "10px" }}
          onClick={setNextPic}
        />
        <div className="property-box-selected-icon d-flex" onClick={savePricesAndToggle}>
          {favorited || dontSelect ? (
            ""
          ) : (
            <div className="selectedProperty-toggle">
              <img
                src={selected ? selectedProperty : unselectedProperty}
                alt=""
              />
            </div>
          )}
          {property && mapOpened && (
            <div className="selectedProperty-toggle">
              <img src={mapIcon} alt="" />
            </div>
          )}
          <div className="onDemand-container">
            {property && isOnDemand(property) && (
              <button
                className="btn-onDemand btn btn-success"
                style={{ fontSize: "14px !important", alignItems: "center" }}
              >
                On Demand
              </button>
            )}
          </div>
          <div className="onDemand-container">
            {agent?.role === 'admin' && property && isSH(property) && (
              <button
                className="btn-onDemand btn btn-success"
                style={{ fontSize: "14px !important", alignItems: "center" }}
              >
                EX Prop
              </button>
            )}
          </div>
          <div className="onDemand-container">
            {property && xdata?.city !== "" && xdata?.city !== null && (
              <button
                className="btn-cityname btn btn-success"
                style={{ fontSize: "14px !important", alignItems: "right" }}
              >
                {property?.address?.city}
              </button>
            )}
          </div>
        </div>
        <div className="property-box-image-footer">
          <span
            className="property-box-image-footer-text"
            title={property?.title}
          >
            {property?.title}
          </span>
        </div>
      </div>
      <div className="property-box-footer row">
        <div className="property-box-footer-left col-md-6 col-sm-6 col-6">
          <div className="property-box-footer-left-icon">
            <img src={peopleIcon} alt="" />
            {property?.accommodates}
          </div>
          <div className="property-box-footer-left-icon">
            <img src={bedsIcon} alt="" />
            {property?.bedrooms > 0 ? property?.bedrooms : "0"}
          </div>
          <div className="property-box-footer-left-icon">
            {screenSize > 800 && (
              <>
                <img src={bathsIcon} alt="" />
                {property?.bathrooms}
              </>
            )}
          </div>
          <div>
            {screenSize > 800 && (
              <div>
                {renderLimitedCollectionIcon("familyCollection", familyIcon)}
                {renderLimitedCollectionIcon("petsCollection", petsIcon)}
                {xdata?.tags?.includes("eventCollection") && (
                  <div className="property-box-footer-left-icon-small">
                    <img src={eventsIcon} alt="Events Icon" />
                  </div>
                )}
                {renderLimitedCollectionIcon("sustainCollection", sustainIcon)}
              </div>
            )}
          </div>
        </div>
        <div className="property-box-footer-right col-md-6 col-sm-6 col-6 h-100">
          <div className="property-box-footer-right-top d-flex align-items-center flex-row-reverse">
            <div
              className="property-box-favorites-icon d-flex flex-1 "
              onClick={toggleFavorites}
            >
              <img src={isFavorite ? likeFull : favoriteIcon} alt="alt" />
            </div>
            {!isOnDemand(property) && (
              <div className="minWmaxW155 d-flex flex-3 align-items-center">
                {refreshPrice === 0 ? (
                  <div className="property-box-footer-right-top-dollar">
                    <sup>
                      <span className="d-inline property-currency">
                        {getCurrencyDisplaySymbol(
                          selectedCurrency?.currency_code
                        )}
                      </span>
                    </sup>
                    <span style={{ marginRight: "5px" }}>
                      {` ${numeral(price?.totalAmount).format("0,0")}`}
                    </span>
                    <sup>
                      <span className="property-box-footer-right-top-night">
                        /night
                      </span>
                    </sup>
                  </div>
                ) : (
                  <div className="property-box-footer-right-top-dollar">
                    <sup>
                      <span className="d-inline property-currency">  {getCurrencyDisplaySymbol(
                        selectedCurrency?.currency_code
                      )}</span>
                    </sup>
                    <span style={{ marginRight: "5px" }}>
                      {price?.totalAmount.toFixed(0)}
                    </span>
                    <sup>
                      <span className="property-box-footer-right-top-night">
                        /period
                      </span>
                    </sup>
                  </div>
                )}
              </div>
            )}
          </div>
          {!isOnDemand(property) && (
            <div className="property-box-footer-right-bottom d-flex flex-row-reverse">
              {refreshPrice === 0 ? (
                <>
                  <div className="minWmaxW120 text-sm property-agency text-agency-comission minWmaxW120">
                    Agency Commission
                  </div>
                  <div className="minWmaxW80 property-box-footer-right-bottom-price">
                    <span className="property-box-footer-right-bottom-dollar">
                      <sup>
                        <span className="d-inline property-currency">
                          {getCurrencyDisplaySymbol(
                            selectedCurrency?.currency_code
                          )}
                        </span>
                      </sup>
                      {` ${numeral((price?.totalAmount) / 10).format(
                        "0,0"
                      )}`}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="minWmaxW120 text-sm property-agency text-agency-comission minWmaxW120">
                    Agency Commission
                  </div>
                  <div className="minWmaxW80 property-box-footer-right-bottom-price">
                    <span className="property-box-footer-right-bottom-dollar">
                      <sup>
                        <span className="d-inline property-currency">
                          {getCurrencyDisplaySymbol(
                            selectedCurrency?.currency_code
                          )}
                        </span>
                      </sup>
                      {` ${numeral((price?.totalAmount) / 10).format(
                        "0,0"
                      )}`}
                    </span>
                  </div>
                </>)}

            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyBox;
